import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useNavigate } from 'react-router-dom';
import './legaldocuments.css';

function LegalDocuments() {

    const navigate = useNavigate();

    return (
        <body>
            <Header />
            <div className="container_body">
                <div className="container_legal center mt-10" onClick={() => navigate("/privacy")}>
                    <span className='legal_text'>Política de privacidad</span>
                </div>
                <div className="container_legal center mt-10" onClick={() => navigate("/terms-use")}>
                    <span className='legal_text'>Términos y condiciones</span>
                </div>
                <div className="mt-10" />
            </div>
            <Footer />
        </body>
    );
}

export default LegalDocuments;
