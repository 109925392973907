import { APP_NAME } from '../..';
import './header.css';

function Header() {
    return (
        <div className='container_header'>
            <p className='label_title_header'>{APP_NAME}</p>
        </div>
    );
}

export default Header;
