import { APP_NAME } from "..";
import Footer from "./footer/Footer";
import Header from "./header/Header";

function App() {

  return (
    <body>
      <Header />
      <div className="container_body">{APP_NAME}</div>
      <Footer />
    </body>
  );
}

export default App;
