import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './component/App';
import GroupJoin from './component/groupJoin/GroupJoin';
import TermsUse from './component/terms-use/TermsUse';
import Privacy from './component/privacy/Privacy';
import LegalDocuments from './component/legal-documents/LegalDocuments';

export const APP_NAME = "Plannits";
export const EMAIL = 'privacy@plannits.es';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/group/:id/join" element={<GroupJoin />} />
        <Route path="/" element={<App />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-use" element={<TermsUse />} />
        <Route path="/legal-documents" element={<LegalDocuments />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

