import { useNavigate } from 'react-router-dom';
import './footer.css';

function Footer() {

    const navigate = useNavigate();

    return (
        <div className='container_footer center'>
            <div className='container_label_title_footer' onClick={() => navigate("/legal-documents")}>Documentos legales</div>
        </div>
    );
}

export default Footer;
