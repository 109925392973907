import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './groupjoin.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { APP_NAME } from '../..';

function GroupJoin() {

    const { id } = useParams();
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `app://${APP_NAME.toLowerCase()}/group/${id}/join`;
        setUrl(url);
    }, [id]);

    const downloadApp = () => {
        console.log('Descarga app');
    }

    return (
        <body>
            <Header />
            <div className="container_body">
                <div>
                    <p className="label_title">¡Bienvenido a {APP_NAME}!</p>
                </div>
                <div onClick={downloadApp}>
                    <p className="label_download_app">Comience descargando la aplicación para realizar actividades inolvidables</p>
                </div>
                <div className="container_button_download">
                    <p>Descargar la aplicación</p>
                </div>
                <div>
                    <a href={url} target='_blank' className={"link"} rel="noreferrer">Ya tengo la aplicación &nbsp;&gt;</a>
                </div>
            </div>
            <Footer />
        </body>
    );
}

export default GroupJoin;
